@import "~antd/dist/antd.less";
@import "react-toastify/dist/ReactToastify.css";
@import "video.js/dist/video-js.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.vjs-has-started[data-is-audio="true"] .vjs-poster {
    display: block;
}
.ant-menu-horizontal{
    border-bottom: unset !important;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 24px;
    line-height: 24px;
}

.ant-menu {
    font-size: 16px;
    font-family: inherit !important;
}

@primary-color: #ED1B32;@link-color: #C41529;