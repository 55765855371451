.listItem {
    @apply p-6 text-center border rounded flex justify-between cursor-pointer hover:bg-primary-white transition items-center;

    .edit {
        @apply opacity-0 transition;
    }

    &:hover {
        .edit {
            @apply opacity-100;
        }
    }

    .active {
        @apply text-secondary-red;
    }
}

@primary-color: #ED1B32;@link-color: #C41529;