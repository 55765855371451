.listItem {
    @apply p-6 text-center border rounded flex justify-between cursor-pointer hover:bg-primary-white transition items-center;

    .edit {
        @apply opacity-0 transition;
    }

    &:hover {
        .edit {
            @apply opacity-100;
        }
    }

    .active {
        @apply text-secondary-red;
    }
}
/* Style the "Update" button */
.updateButton {
    background-color: #17a2b8 !important; /* Green background color */
    color: white; /* White text color */
    border-radius: 4px; /* Rounded corners */
    margin-left: 10px;
    transition: background-color 0.3s; /* Transition effect for background color */
}

/* Hover effect for the "Update" button */
.updateButton:hover {
    background-color: #17a2b8; /* Darker green background color on hover */
}

@primary-color: #ED1B32;@link-color: #C41529;